import { HistoricalConversation, HistoricalWorkItem } from '@buzzeasy/shared-frontend-utilities';
import { InternalHistoricalConversation, InternalHistoricalWorkItem } from '../../dashboard/models/reports.historical';

export function mapToInternalHistoricalWorkItem(external: HistoricalWorkItem): InternalHistoricalWorkItem {
  return {
    ...external,
    isOutbound: external.conversations.find(c => c.id === external.primaryConversationId)?.isOutbound ?? true,
    startTimeUtc: new Date(external.startTimeUtc),
    endTimeUtc: new Date(external.endTimeUtc),
    conversations: external.conversations.map(mapToInternalHistoricalConversation),
    agent: external.agent
      ? {
        ...external.agent,
        deletedAt: new Date(external.agent.deletedAt),
      }
      : undefined,
    channel: external.channel
      ? {
        ...external.channel,
        deletedAt: new Date(external.channel.deletedAt),
      }
      : undefined,
    queue: external.queue
      ? {
        ...external.queue,
        deletedAt: new Date(external.queue.deletedAt),
      }
      : undefined,
  };
}

// fix later in shared-frontend-utilities
export interface MissingHistoricalConversationProps {
  customerEmailAddress: string | null;
  customerPhoneNumber: string | null;
}

function mapToInternalHistoricalConversation(external: HistoricalConversation): InternalHistoricalConversation {
  const ext = external as HistoricalConversation & MissingHistoricalConversationProps;
  
  return {
    ...ext,
    startedAtUtc: new Date(ext.startedAtUtc),
    endedAtUtc: new Date(ext.endedAtUtc),
    customer: ext.customerId
      ? {
        id: ext.customerId,
        firstName: ext.customerFirstName ?? '',
        middleName: ext.customerMiddleName ?? '',
        lastName: ext.customerLastName ?? '',
        emailAddress: ext.customerEmailAddress,
        phoneNumber: ext.customerPhoneNumber,
      }
      : undefined,
  };
}