import { createSlice, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface AuthInfo {
  token: string;
  tenantId: string;
  user: {
    id: string;
    name: string | undefined;
    email: string | undefined;
    preferredUsername: string | undefined;
  };
}

const initialState: AuthInfo = {
  token: '',
  tenantId: '',
  user: {
    id: '',
    name: '',
    email: '',
    preferredUsername: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthInfo: (_, { payload }: PayloadAction<AuthInfo>) => ({ ...payload }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (_, { payload }) => {
      if (payload && typeof payload === 'object' && 'status' in payload) {
        if (payload.status === 401)
          window.location.reload();
      }
    });
  },
});

export const { setAuthInfo } = authSlice.actions;
export const selectAuthInfo = (state: RootState): AuthInfo => state.auth;

export default authSlice;