import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../helpers';

export interface WebChatTenantConfig {
  customerTrackingEnabled: boolean;
}

const webChatApi = createApi({
  reducerPath: 'webChat',
  tagTypes: [],
  baseQuery: apiBaseQuery(),
  endpoints: (builder) => ({
    getWebChatTenantConfig: builder.query<WebChatTenantConfig, void>({
      query: () => '/webchat/tenant/settings',
    }),
  }),
});

export const {
  useGetWebChatTenantConfigQuery,
} = webChatApi;

export default webChatApi;