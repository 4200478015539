import { createApi } from '@reduxjs/toolkit/query/react';
import { MINUTE } from '../../hooks/useTimeFormatters.helpers';
import { Application, Layout, SessionRecordingConfig } from '../../models/misc';
import { apiBaseQuery } from '../helpers';

interface Config {
  configValue: string;
}

const frontendConfigStoreApi = createApi({
  reducerPath: 'frontendConfigStore',
  tagTypes: [],
  baseQuery: apiBaseQuery('/frontendstore'),
  endpoints: (builder) => ({
    getAgentApplications: builder.query<Application[], void>({
      query: () => '/configs/agentApplications',
      transformResponse: (resp: Config) => JSON.parse(resp.configValue),
    }),
    getAgentLayouts: builder.query<Layout[], void>({
      query: () => '/configs/agentLayouts',
      transformResponse: (resp: Config) => JSON.parse(resp.configValue),
      keepUnusedDataFor: 5 * MINUTE,
    }),
    getSessionRecordingConfig: builder.query<SessionRecordingConfig, void>({
      query: () => '/configs/sessionRecording',
      transformResponse: (resp: Config) => JSON.parse(resp.configValue),
    }),
  }),
});

export const {
  useGetAgentApplicationsQuery,
  useGetAgentLayoutsQuery,
  useGetSessionRecordingConfigQuery,
} = frontendConfigStoreApi;

export default frontendConfigStoreApi;