import { PropsWithChildren, ReactElement, createContext, useEffect } from 'react';
import environmentConfig from '../../../environmentConfig';
import { useLoginMutation } from '../../../redux/apis/blenderApi';
import PopupAuthHandler from './AuthProvider.PopupHandler';
import RedirectAuthHandler from './AuthProvider.RedirectHandler';
import UserManagerProvider from './UserManagerProvider';

const { isInIframe } = environmentConfig;

export type AuthState = 'notAuthenticated' | 'inProgress' | 'authenticated' | 'failed';
export const preferredLoginTenantKey = 'aui-preferredLoginTenant';

export const loggedOutPath = '/logged-out.html';
export const crmLoggedOutPath = '/crm/logged-out.html';

export const AuthContext = createContext<{ logout(): void }>({ logout: () => { /* empty */ } });

/**
 * Logs into BlenderGara upon launching the app.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 */
function BlenderLoginHandler({ children }: PropsWithChildren): ReactElement {
  const [loginToBlender] = useLoginMutation();

  useEffect(
    () => { loginToBlender(); },
    [loginToBlender],
  );

  return <>{children}</>;
}

/**
 * Handles all authentication related functionality. Until the user is authenticated it will **not** render the children but a loading/error screen.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 * - ThemeProviders
 * - TranslationProvider
 */
export default function AuthProvider({ children }: PropsWithChildren): ReactElement {

  const content = (
    <BlenderLoginHandler>
      {children}
    </BlenderLoginHandler>
  );

  return (
    <UserManagerProvider>
      {
        (isInIframe || window.opener)
          ? (
            <PopupAuthHandler>
              {content}
            </PopupAuthHandler>
          )
          : (
            <RedirectAuthHandler>
              {content}
            </RedirectAuthHandler>
          )
      }
    </UserManagerProvider>
  );
}