import { RawBusinessUnit } from '@buzzeasy/shared-frontend-utilities/agentHubEvents';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery, post, put } from '../helpers';

export type ManualAssignResult = 'Ok' | 'NotFound' | 'NotStaffed';

export interface CompleteWorkItemParams {
  workItemId: string;
  closureCode: string;
  notes: string;
}

const blenderApi = createApi({
  reducerPath: 'blender',
  tagTypes: ['BusinessUnits'],
  baseQuery: apiBaseQuery('/blender'),
  endpoints: (builder) => ({
    login: builder.mutation<void, void>({
      query: () => post('/resource/login', {}),
    }),
    logout: builder.mutation<void, void>({
      query: () => post('/resource/logout', {}),
    }),
    goToAvailable: builder.mutation<void, void>({
      query: () => post('/resource/activate', {}),
    }),
    goToBreak: builder.mutation<void, string | null>({
      query: (breakName) => post(`/resource/moveToBreak?breakName=${breakName ?? ''}`, {}),
    }),
    reserveForLookup: builder.mutation<{ workItemId: string | null }, void>({
      query: () => post('/resource/reserveForLookup', {}),
    }),
    assignToMe: builder.mutation<ManualAssignResult, string>({
      query: (conversationId) => post(`/resource/${conversationId}/assignToMe`, {}),
      transformResponse: (data: { result: ManualAssignResult }) => data.result,
    }),
    completeWorkItem: builder.mutation<void, CompleteWorkItemParams>({
      query: (params) => post('/resource/completeWorkItem', params),
    }),
    getBreakNames: builder.query<string[], void>({
      query: () => '/resource/breakNames',
    }),
    resetWrapUp: builder.mutation<void, string>({
      query: (workItemId) => post(`/resource/resetWrapUp?workItemId=${workItemId}`, {}),
    }),
    getBusinessUnits: builder.query<RawBusinessUnit[], void>({
      query: () => '/resource/businessUnits',
      providesTags: ['BusinessUnits'],
    }),
    updateBusinessUnit: builder.mutation<void, RawBusinessUnit>({
      query: (businessUnits) => put('/resource/businessUnits', [businessUnits]), // manually map to array because the API could actually handle more than one BU change at a time
      invalidatesTags: ['BusinessUnits'],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGoToAvailableMutation,
  useGoToBreakMutation,
  useReserveForLookupMutation,
  useAssignToMeMutation,
  useCompleteWorkItemMutation,
  useGetBreakNamesQuery,
  useResetWrapUpMutation,
  useGetBusinessUnitsQuery,
  useUpdateBusinessUnitMutation,
} = blenderApi;

export default blenderApi;