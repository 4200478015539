import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import blenderApi from './apis/blenderApi';
import contentManagerApi from './apis/contentManagerApi';
import frontendConfigStoreApi from './apis/frontendConfigStoreApi';
import historicalDataApi from './apis/historicalDataApi';
import reportingThresholdsApi from './apis/reportingThresholdsApi';
import teamsApi from './apis/teamsApi';
import webChatApi from './apis/webChatApi';
import workflowApi from './apis/workflowApi';
import authSlice from './authSlice';

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [blenderApi.reducerPath]: blenderApi.reducer,
    [contentManagerApi.reducerPath]: contentManagerApi.reducer,
    [frontendConfigStoreApi.reducerPath]: frontendConfigStoreApi.reducer,
    [historicalDataApi.reducerPath]: historicalDataApi.reducer,
    [reportingThresholdsApi.reducerPath]: reportingThresholdsApi.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
    [webChatApi.reducerPath]: webChatApi.reducer,
    [workflowApi.reducerPath]: workflowApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [`${historicalDataApi.reducerPath}/executeQuery/fulfilled`],
      ignoredPaths: [`${historicalDataApi.reducerPath}.queries`],
    },
  }).concat(
    blenderApi.middleware,
    contentManagerApi.middleware,
    frontendConfigStoreApi.middleware,
    historicalDataApi.middleware,
    reportingThresholdsApi.middleware,
    teamsApi.middleware,
    webChatApi.middleware,
    workflowApi.middleware,
  ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();