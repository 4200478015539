import { HistoricalWorkItem } from '@buzzeasy/shared-frontend-utilities';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { HistoricalBusinessUnit, HistoricalWorkItemRelatedEntity, InternalHistoricalWorkItem } from '../../dashboard/models/reports.historical';
import authSlice, { AuthInfo } from '../authSlice';
import { odataBaseQuery, post } from '../helpers';
import { mapToInternalHistoricalWorkItem } from './historicalDataApi.mappers';
import { buildWorkItemQueryString, buildWorkItemRelationsQueryParams, WorkItemFilters, WorkItemRelatedEntityType } from './historicalDataApi.query';

interface ODataResult<T> {
  '@odata.count': number;
  'value': T[];
}

const historicalDataApi = createApi({
  reducerPath: 'historicalData',
  tagTypes: ['WorkItems', 'BusinessUnit'],
  baseQuery: odataBaseQuery(''),
  endpoints: (builder => ({
    searchWorkItems: builder.query<{ total: number; workItems: InternalHistoricalWorkItem[] }, Partial<WorkItemFilters>>({
      queryFn: async (params, api, _, baseQuery) => {
        const userId = (api.getState() as { [authSlice.name]: AuthInfo }).auth.user.id;
        const result = await (baseQuery(`/v1/workItems${buildWorkItemQueryString(params, userId)}`) as MaybePromise<QueryReturnValue<ODataResult<HistoricalWorkItem>, FetchBaseQueryError, unknown>>);

        if (result.error)
          return result;

        return {
          ...result,
          data: { total: result.data['@odata.count'], workItems: result.data.value.map(mapToInternalHistoricalWorkItem) },
        };
      },
      providesTags: (params) => [{ type: 'WorkItems', id: JSON.stringify(params) }],
    }),
    getHistoricalBusinessUnit: builder.query<HistoricalBusinessUnit, string>({
      query: (id) => `/v1/businessUnits/${id}`,
      providesTags: (_, __, id) => [{ type: 'BusinessUnit', id }],
    }),
    getWorkItemRelations: builder.query<HistoricalWorkItemRelatedEntity[], { entityType: WorkItemRelatedEntityType; filters: Partial<WorkItemFilters> }>({
      queryFn: async ({ entityType, filters }, api, _, baseQuery) => {
        const userId = (api.getState() as { [authSlice.name]: AuthInfo }).auth.user.id;
        const result = await (baseQuery(post(`/extras/workItems/relations/${entityType}`, buildWorkItemRelationsQueryParams(filters, userId))) as MaybePromise<QueryReturnValue<HistoricalWorkItemRelatedEntity[], FetchBaseQueryError, unknown>>);

        if (result.error)
          return result;

        return result;
      },
    }),
  })),
});

export default historicalDataApi;

export const {
  useSearchWorkItemsQuery,
  useGetHistoricalBusinessUnitQuery,
  useGetWorkItemRelationsQuery,
} = historicalDataApi;