import { createApi } from '@reduxjs/toolkit/query/react';
import { Team } from '../../dashboard/models/team';
import { apiBaseQuery } from '../helpers';

const teamsApi = createApi({
  reducerPath: 'teams',
  tagTypes: [],
  baseQuery: apiBaseQuery(),
  endpoints: (builder) => ({
    getMyTeams: builder.query<Team[], void>({
      query: () => '/me/teams',
    }),
  }),
});

export const {
  useGetMyTeamsQuery,
} = teamsApi;

export default teamsApi;