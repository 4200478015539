import { TFunction } from 'i18next';

export function formatNum(num: number): string { return `${num >= 10 ? '' : '0'}${num}`; }

export const MINUTE = 60;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export function breakDownSeconds(seconds: number): { days: number; hours: number; minutes: number; seconds: number } {
  const result = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  let remainingSeconds = seconds;

  if (remainingSeconds >= DAY) {
    result.days = Math.floor(remainingSeconds / DAY);
    remainingSeconds -= result.days * DAY;
  }

  if (remainingSeconds >= HOUR) {
    result.hours = Math.floor(remainingSeconds / HOUR);
    remainingSeconds -= result.hours * HOUR;
  }

  if (remainingSeconds >= MINUTE) {
    result.minutes = Math.floor(remainingSeconds / MINUTE);
    remainingSeconds -= result.minutes * MINUTE;
  }

  result.seconds = Math.floor(remainingSeconds);
  return result;
}

export function formatDateDefault(date: Date) {
  return date.toLocaleDateString();
}

export function formatDateISO(date: Date) {
  return `${date.getFullYear()}-${formatNum(date.getMonth() + 1)}-${formatNum(date.getDate())}`;
}

export function formatDateEU(date: Date) {
  return `${formatNum(date.getDate())}/${formatNum(date.getMonth() + 1)}/${date.getFullYear()}`;
}

export function formatDateUS(date: Date) {
  return `${formatNum(date.getMonth() + 1)}/${formatNum(date.getDate())}/${date.getFullYear()}`;
}

export function formatDateWritten(date: Date) {
  return `${date.toLocaleDateString(undefined, { month: 'short' })} ${formatNum(date.getDate())}, ${date.getFullYear()}`;
}

export function formatTimeDefault(date: Date) {
  return date.toLocaleTimeString();
}

export function formatTime24Hour(date: Date, includeSeconds?: boolean) {
  const time = `${formatNum(date.getHours())}:${formatNum(date.getMinutes())}`;

  if (includeSeconds)
    return `${time}:${formatNum(date.getSeconds())}`;
  else
    return time;
}

export function formatTime12Hour(t: TFunction<'translation'>, date: Date, includeSeconds?: boolean) {
  let hours = date.getHours();
  let amPm = t('dateTime.am');

  if (hours === 0) {
    hours = 12;
  }
  else if (hours > 12) {
    hours -= 12;
    amPm = t('dateTime.pm');
  }

  const time = `${hours}:${formatNum(date.getMinutes())}`;

  if (includeSeconds)
    return `${time}:${formatNum(date.getSeconds())} ${amPm}`;
  else
    return `${time} ${amPm}`;
}

export function formatTimeSpanNormal(t: TFunction<'translation'>, timeSeconds: number): string {
  const { days, hours, minutes, seconds } = breakDownSeconds(Math.abs(timeSeconds));
  let value = '';

  if (days > 0) {
    value = `${days}${t('dateTime.dayShorthand')} ${hours}${t('dateTime.hourShorthand')}`;
  }
  else {
    if (hours > 0)
      value = `${hours}:${formatNum(minutes)}:${formatNum(seconds)}`;
    else
      value = `${minutes}:${formatNum(seconds)}`;
  }

  return timeSeconds < 0
    ? t('dateTime.remainingX', { value })
    : value;
}

export function formatTimeSpanWritten(t: TFunction<'translation'>, timeSeconds: number): string {
  const { days, hours, minutes, seconds } = breakDownSeconds(Math.abs(timeSeconds));
  let value = '';

  if (days > 0) {
    value = `${days}${t('dateTime.dayShorthand')}\xa0${hours}${t('dateTime.hourShorthand')}`; // nbsp
  }
  else {
    if (timeSeconds === 0 || seconds !== 0)
      value = `${seconds}${t('dateTime.secondShorthand')}`;

    if (minutes > 0)
      value = `${minutes}${t('dateTime.minuteShorthand')}${value ? '\xa0' : ''}${value}`; // nbsp

    if (hours > 0)
      value = `${hours}${t('dateTime.hourShorthand')}${value ? '\x20' : ''}${value}`; // regular (breaking) space
  }

  return timeSeconds < 0
    ? t('dateTime.remainingX', { value })
    : value;
}