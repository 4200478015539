import { MediaGroup } from '@buzzeasy/shared-frontend-utilities';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ContentManagerConfig, ConversationSummary } from '../../models/contentManager';
import { apiBaseQuery } from '../helpers';

const contentManagerApi = createApi({
  reducerPath: 'contentManager',
  tagTypes: [],
  baseQuery: apiBaseQuery('/content'),
  endpoints: (builder) => ({
    getContentManagerConfig: builder.query<ContentManagerConfig, void>({
      query: () => '/tenant/settings',
    }),
    getConversationSummary: builder.query<ConversationSummary, { mediaGroup: MediaGroup; conversationId: string }>({
      query: ({ mediaGroup, conversationId }) => `/conversations/${conversationId}/summary?mediaGroup=${mediaGroup}`,
    }),
  }),
});

export const {
  useGetContentManagerConfigQuery,
  useLazyGetConversationSummaryQuery,
} = contentManagerApi;

export default contentManagerApi;