import dayjs from 'dayjs';
import buildQuery, { Filter } from 'odata-query';

export const workItemRelatedEntityTypes = ['Channel', 'Queue', 'CompletionCode'] as const;
export type WorkItemRelatedEntityType = typeof workItemRelatedEntityTypes[number];

export interface WorkItemFilters {
  customerIdentifierSearchText: string;
  mediaGroup: 'voice' | 'chat' | 'email';
  isOutbound: boolean;
  completionCodes: string[];
  channelIds: string[];
  queueIds: string[];
  businessUnitId: string | null;
  from: Date;
  to: Date;
}

export function buildWorkItemQueryString(params: Partial<WorkItemFilters>, agentId: string) {
  const filters: Filter<unknown>[] = [];

  filters.push({ agentId });
 
  if (params.customerIdentifierSearchText)
    filters.push({ conversations: { any: { or: [{ customerEmailAddress: { contains: params.customerIdentifierSearchText } }, { customerPhoneNumber: { contains: params.customerIdentifierSearchText } }] } } });

  if (params.mediaGroup)
    filters.push({ mediaType: mediaGroupToFilter(params.mediaGroup) });
  else
    filters.push({ mediaType: { ne: 'Lookup' } });

  if (params.isOutbound !== undefined) {
    if (params.isOutbound)
      filters.push({ or: [{ campaignId: { ne: null } }, { primaryConversation: { isOutbound: true } }] });
    else
      filters.push({ and: [{ campaignId: { eq: null } }, { primaryConversation: { isOutbound: false } }] });
  }

  if (params.completionCodes && params.completionCodes.length > 0)
    filters.push({ completionCode: { in: params.completionCodes } });

  if (params.channelIds && params.channelIds.length > 0)
    filters.push({ primaryChannelId: { in: params.channelIds } });

  if (params.queueIds && params.queueIds.length > 0)
    filters.push({ queueId: { in: params.queueIds } });

  if (params.businessUnitId !== undefined)
    filters.push({ businessUnitId: params.businessUnitId });

  if (params.from)
    filters.push({ startTimeUtc: { ge: params.from } });

  if (params.to)
    filters.push({ endTimeUtc: { le: params.to } });

  return buildQuery({
    count: true,
    expand: ['Agent', 'Channel', 'Queue', 'Conversations'],
    orderBy: 'endTimeUtc desc',
    filter: { and: filters },
  });
}

function mediaGroupToFilter(mediaGroup: WorkItemFilters['mediaGroup']): Filter<unknown> {
  switch (mediaGroup) {
    case 'chat':
      return { in: ['webchat', 'messaging'] };
    case 'email':
    case 'voice':
      return mediaGroup;
  }
}

export interface WorkItemRelatedEntityFilters {
  mediaGroup: 'voice' | 'chat' | 'email';
  isOutbound: boolean;
  from: Date;
  to: Date;
}

export function buildWorkItemRelationsQueryParams(params: Partial<WorkItemRelatedEntityFilters>, agentId: string) {
  return {
    ...params,
    to: params.to ?? dayjs().endOf('day').toDate(), // defaulting is needed here because the API defaults to 0001-01-01T00:00:00.000Z :D
    agentIds: [agentId],
  };
}