import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import { PropsWithChildren, ReactElement, createContext, useEffect, useMemo } from 'react';
import environmentConfig from '../../../environmentConfig';
import { crmLoggedOutPath, loggedOutPath } from './AuthProvider';

const { authority, clientId, crmMode } = environmentConfig;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const UserManagerContext = createContext<UserManager>(undefined!);

/**
 * Providers a UserManager accessible to child components and handles authentication callbacks.
 *
 * **No provider dependencies**
 */
export default function UserManagerProvider({ children }: PropsWithChildren): ReactElement {
  const userManager = useMemo(
    () => {
      const acrValues: string[] = ['idp:aad.oidc'];

      const settings: UserManagerSettings = {
        authority,
        client_id: clientId,
        redirect_uri: location.origin + (crmMode ? '/crm/' : '/'),
        silent_redirect_uri: location.origin + '/silent-auth.html',
        post_logout_redirect_uri: location.origin + (crmMode ? crmLoggedOutPath : loggedOutPath),
        response_type: 'code',
        response_mode: 'query',
        scope: 'openid profile email phone',
        loadUserInfo: true,
        automaticSilentRenew: true,
        accessTokenExpiringNotificationTimeInSeconds: 300,
        acr_values: acrValues.length > 0 ? acrValues.join(' ') : undefined,
      };

      return new UserManager(settings);
    },
    [],
  );

  useEffect(
    () => {
      function handleTokenExpired() {
        window.location.reload();
      }

      userManager.events.addAccessTokenExpired(handleTokenExpired);
      return () => userManager.events.removeAccessTokenExpired(handleTokenExpired);
    },
    [userManager.events],
  );

  return (
    <UserManagerContext.Provider value={userManager}>
      {children}
    </UserManagerContext.Provider>
  );
}