import { createApi } from '@reduxjs/toolkit/query/react';
import { QueueKpiThresholds, TeamKpiThresholds } from '../../dashboard/models/reports.thresholds';
import { apiBaseQuery } from '../helpers';

const reportingThresholdsApi = createApi({
  reducerPath: 'reportingThresholds',
  tagTypes: [],
  baseQuery: apiBaseQuery(),
  endpoints: (builder) => ({
    getTeamReportingThresholds: builder.query<Partial<TeamKpiThresholds>, string>({
      query: (kpiId) => `/kpithresholds/teams/${kpiId}`,
      transformResponse: (data: { thresholds: string } | null) => data ? JSON.parse(data.thresholds) : {},
    }),
    getQueueReportingThresholds: builder.query<Partial<QueueKpiThresholds>, string>({
      query: (kpiId) => `/kpithresholds/queues/${kpiId}`,
      transformResponse: (data: { thresholds: string } | null) => data ? JSON.parse(data.thresholds) : {},
    }),
  }),
});

export const {
  useGetTeamReportingThresholdsQuery,
  useGetQueueReportingThresholdsQuery,
} = reportingThresholdsApi;

export default reportingThresholdsApi;