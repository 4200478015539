import { setItem } from '@buzzeasy/shared-frontend-utilities';
import jwtDecode from 'jwt-decode';
import { User } from 'oidc-client-ts';
import { PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import environmentConfig from '../../../environmentConfig';
import { useLogoutMutation } from '../../../redux/apis/blenderApi';
import { selectAuthInfo, setAuthInfo } from '../../../redux/authSlice';
import { useAppDispatch } from '../../../redux/store';
import LoginScreens from '../../presenters/LoginScreens';
import { AuthContext, AuthState, crmLoggedOutPath, loggedOutPath, preferredLoginTenantKey } from './AuthProvider';
import { hasAuthParams } from './AuthProvider.helpers';
import { UserManagerContext } from './UserManagerProvider';

const { crmMode } = environmentConfig;

/**
 * Handles authentication using the popup method. Suited for uses where redirect is not allowed (like AUI running inside an iframe).
 *
 * **Provider dependencies:**
 * - UserManagerProvider
 */
export default function PopupAuthHandler({ children }: PropsWithChildren): ReactElement {
  const userManager = useContext(UserManagerContext);
  const { token } = useSelector(selectAuthInfo);

  const [authState, setAuthState] = useState<AuthState>('notAuthenticated');
  const [user, setUser] = useState<User | undefined>(undefined);

  const appDispatch = useAppDispatch();

  const authenticate = useCallback(
    async () => {
      setAuthState('inProgress');

      try {
        const u = await userManager.signinPopup();
        const tenantId = jwtDecode<{ tenant: string }>(u.access_token).tenant;

        setUser(u);
        setItem(preferredLoginTenantKey, tenantId);

        appDispatch(setAuthInfo({
          token: u.access_token,
          tenantId,
          user: {
            id: u.profile.sub,
            name: u.profile.name,
            email: u.profile.email,
            preferredUsername: u.profile.preferred_username,
          },
        }));

        setAuthState('authenticated');
      }
      catch {
        setAuthState('failed');
      }
    },
    [appDispatch, userManager],
  );

  useEffect(
    () => {
      if (hasAuthParams()) {
        userManager.signinPopupCallback();
      }
      if (!hasAuthParams() && !user && authState === 'notAuthenticated' && !window.opener) {
        authenticate();
      }
    },
    [authState, authenticate, user, userManager],
  );

  const [logoutOfBlender] = useLogoutMutation();

  const logout = useCallback(
    () => {
      logoutOfBlender();
      userManager.signoutPopup();
      window.location.replace(crmMode ? crmLoggedOutPath : loggedOutPath);
    },
    [logoutOfBlender, userManager],
  );

  // to make contextValue static
  const logoutRef = useRef(logout);
  logoutRef.current = logout;

  const contextValue = useMemo(
    () => ({ logout: logoutRef.current }),
    [],
  );

  if (hasAuthParams()) {
    return <></>;
  }

  if (authState === 'failed')
    return <LoginScreens.AuthenticationFailed popupAuthentication onTryAgainClick={authenticate} />;
  if (authState === 'inProgress' || !user || !token)
    return <LoginScreens.Authenticating popupAuthentication />;
  if (!user?.scopes.includes('bp.access'))
    return <LoginScreens.NoAccess />;

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}