import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import environmentConfig from '../environmentConfig';
import { RootState } from './store';

const { apiBaseUrl, odataBaseUrl } = environmentConfig;

export function prepareHeaders(headers: Headers, { getState }: { getState(): unknown }): Headers {
  const { token } = (getState() as RootState).auth;

  if (!token)
    throw new Error('Empty \'token\' found in state.');

  headers.set('authorization', `Bearer ${token}`);

  return headers;
}

export function apiBaseQuery(apiPath?: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {

  return async (args, store, extraOptions) => {
    const rawBaseQuery = fetchBaseQuery({
      baseUrl: new URL(apiPath ?? '', apiBaseUrl).href,
      prepareHeaders,
    });

    return rawBaseQuery(args, store, extraOptions);
  };
}

export function odataBaseQuery(apiPath?: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {

  return async (args, store, extraOptions) => {
    const rawBaseQuery = fetchBaseQuery({
      baseUrl: new URL(apiPath ?? '', odataBaseUrl).href,
      prepareHeaders,
    });

    return rawBaseQuery(args, store, extraOptions);
  };
}

export const post = <T>(url: string, body: NonNullable<T>): FetchArgs => ({
  url,
  method: 'POST',
  body,
});

export const put = <T>(url: string, body: NonNullable<T>): FetchArgs => ({
  url,
  method: 'PUT',
  body,
});

export const patch = <T>(url: string, body?: NonNullable<T>): FetchArgs => ({
  url,
  method: 'PATCH',
  body,
});

export const del = <T>(url: string, body?: NonNullable<T>): FetchArgs => ({
  url,
  method: 'DELETE',
  body,
});

interface PatchAdd {
  op: 'add';
  path: string;
  value: unknown;
}

interface PatchRemove {
  op: 'remove';
  path: string;
}

interface PatchReplace {
  op: 'replace';
  path: string;
  value: unknown;
}

interface PatchMove {
  op: 'move';
  path: string;
  from: string;
}

interface PatchCopy {
  op: 'copy';
  path: string;
  from: string;
}

interface PatchTest {
  op: 'test';
  path: string;
  value: unknown;
}

export type JsonPatchData = (PatchAdd | PatchRemove | PatchReplace | PatchMove | PatchCopy | PatchTest)[];