import { Alert, Button, Space } from 'antd';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CenterContent from '../layouts/CenterContent';
import FullScreenSpinner from './FullScreenSpinner';

const StyledCenterContent = styled(CenterContent)`
  padding: ${p => p.theme.padding}px;

  color: ${p => p.theme.colorText};
  background-color: ${p => p.theme.colorBgContainer};
`;

interface AuthenticatingProps {
  popupAuthentication?: boolean;
}

function Authenticating({ popupAuthentication }: AuthenticatingProps): ReactElement {
  const { t } = useTranslation();
  return (
    <StyledCenterContent>
      <FullScreenSpinner
        tip={popupAuthentication
          ? t('ui.loginScreen.authenticating.popupTip')
          : t('ui.loginScreen.authenticating.tip') + '...'
        }
      />
    </StyledCenterContent>
  );
}

interface AuthenticationFailedProps {
  popupAuthentication?: boolean;
  onTryAgainClick(): void;
}

function AuthenticationFailed({ popupAuthentication, onTryAgainClick }: AuthenticationFailedProps): ReactElement {
  const { t } = useTranslation();

  return (
    <StyledCenterContent>
      <Space direction="vertical" size="large">
        <Space direction="vertical" size="small">
          <Alert showIcon type="error" message={t('ui.loginScreen.authFailed.message')} />
          {
            popupAuthentication &&
            <Alert showIcon type="info" message={<Trans i18nKey="ui.loginScreen.authFailed.popupHint" />} />
          }
        </Space>
        <CenterContent>
          <Button type="primary" onClick={onTryAgainClick}>{t('ui.loginScreen.authFailed.tryAgain')}</Button>
        </CenterContent>
      </Space>
    </StyledCenterContent>
  );
}

function NoAccess(): ReactElement {
  const { t } = useTranslation();
  return <StyledCenterContent><Alert showIcon type="error" message={t('ui.loginScreen.noAccess')} /></StyledCenterContent>;
}

export default {
  Authenticating,
  AuthenticationFailed,
  NoAccess,
};