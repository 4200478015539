import { setItem, usePureEffect } from '@buzzeasy/shared-frontend-utilities';
import jwtDecode from 'jwt-decode';
import { User } from 'oidc-client-ts';
import { PropsWithChildren, ReactElement, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import environmentConfig from '../../../environmentConfig';
import { useLogoutMutation } from '../../../redux/apis/blenderApi';
import { selectAuthInfo, setAuthInfo } from '../../../redux/authSlice';
import { useAppDispatch } from '../../../redux/store';
import LoginScreens from '../../presenters/LoginScreens';
import { AuthContext, AuthState, preferredLoginTenantKey } from './AuthProvider';
import { hasAuthParams } from './AuthProvider.helpers';
import { UserManagerContext } from './UserManagerProvider';

const { crmMode } = environmentConfig;

/**
 * Handles authentication using the redirect method. Easy and convenient method that should be used where redirect is allowed.
 *
 * **Provider dependencies:**
 * - UserManagerProvider
 * - ThemeProviders (because of the {@link LoginScreens})
 * - TranslationProvider (because of the {@link LoginScreens})
 */
export default function RedirectAuthHandler({ children }: PropsWithChildren): ReactElement {
  const userManager = useContext(UserManagerContext);
  const { token } = useSelector(selectAuthInfo);

  const [authState, setAuthState] = useState<AuthState>('notAuthenticated');
  const [user, setUser] = useState<User | undefined>(undefined);

  const appDispatch = useAppDispatch();

  const handleLoggedIn = useCallback(
    (u: User) => {
      setAuthState('authenticated');
      setUser(u);

      const tenantId = jwtDecode<{ tenant: string }>(u.access_token).tenant;
      setItem(preferredLoginTenantKey, tenantId);

      appDispatch(setAuthInfo({
        token: u.access_token,
        tenantId,
        user: {
          id: u.profile.sub,
          name: u.profile.name,
          email: u.profile.email,
          preferredUsername: u.profile.preferred_username,
        },
      }));

      window.history.replaceState(null, '_unused_', crmMode ? '/crm/' : '/');
    },
    [appDispatch],
  );

  const startLogin = useCallback(
    async () => {
      setAuthState('inProgress');

      try {
        const existingUser = await userManager.getUser();

        if (!existingUser || existingUser.expired)
          await userManager.signinRedirect();
        else
          handleLoggedIn(existingUser);
      }
      catch (err) {
        setAuthState('failed');
      }
    },
    [handleLoggedIn, userManager],
  );

  const handleLoginCallback = useCallback(
    async () => {
      try {
        const createdUser = await userManager.signinCallback();

        if (createdUser)
          handleLoggedIn(createdUser);
        else
          setAuthState('failed');
      }
      catch (err) {
        setAuthState('failed');
      }
    },
    [handleLoggedIn, userManager],
  );

  usePureEffect(
    (deps) => {
      if (hasAuthParams()) {
        deps.handleLoginCallback();
      }
      else if (!user && authState === 'notAuthenticated') {
        deps.startLogin();
      }
    },
    [authState, user],
    { handleLoginCallback, startLogin },
  );

  const [logoutOfBlender] = useLogoutMutation();

  const logout = useCallback(
    () => {
      logoutOfBlender();
      userManager.signoutRedirect();
    },
    [logoutOfBlender, userManager],
  );

  // to make contextValue static
  const logoutRef = useRef(logout);
  logoutRef.current = logout;

  const contextValue = useMemo(
    () => ({ logout: logoutRef.current }),
    [],
  );

  if (authState === 'failed')
    return <LoginScreens.AuthenticationFailed onTryAgainClick={startLogin} />;
  if (authState === 'inProgress' || !user || !token)
    return <LoginScreens.Authenticating />;
  if (!user?.scopes.includes('bp.access'))
    return <LoginScreens.NoAccess />;

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}